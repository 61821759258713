
import { defineComponent, reactive, toRefs, ref, onMounted } from "vue";
import { Api } from "@/assets/Api";
import { ElMessage } from "element-plus";
import * as echarts from "echarts";

export default defineComponent({
  name: "ReportRegister",
  components: {},
  setup() {
    let data = reactive<any>({
      formInline: {
        year: 2022,
      },
      series: [], //柱状图
      data: [], //饼图
    });

    const Reset = () => {
      RegisterAnnualReportByProvince();
      RegisterAnnualReportByDocking();
    };

    const RegisterAnnualReportByProvince = () => {
      Api.Report.RegisterAnnualReportByProvince(data.formInline.year).then(
        (res: any) => {
          console.log(res);
          if (res.Code == 0) {
            var josnReport = JSON.parse(res.Data);
            console.log(josnReport);
            var series: any = [];
            josnReport.forEach((r: any) => {
              var xxx = series.filter((s: any) => {
                return s.name == r.province;
              });
              if (xxx.length == 0) {
                var item = {
                  name: r.province,
                  type: "bar",
                  stack: "省份",
                  label: {
                    show: true,
                  },
                  emphasis: {
                    focus: "series",
                  },
                  data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                };
                item.data[r.month - 1] = r.successCount + r.failCount;
                series.push(item);
              } else {
                xxx[0].data[r.month - 1] = r.successCount + r.failCount;
              }
            });
            data.series = series;
            initBarEcharts();
            console.log(series);
          } else {
            ElMessage.warning({
              showClose: true,
              duration: 10000,
              message: res.ResultMsg,
            });
          }
        }
      );
    };

    const RegisterAnnualReportByDocking = () => {
      Api.Report.RegisterAnnualReportByDocking(data.formInline.year).then(
        (res: any) => {
          console.log(res);
          if (res.Code == 0) {
            var josnReport = JSON.parse(res.Data);
            console.log(josnReport);
            var series: any = [];
            josnReport.forEach((r: any) => {
              var xxx = series.filter((s: any) => {
                return s.name == (r.docking ? "直连" : "非直连");
              });
              if (xxx.length == 0) {
                var item = {
                  name: r.docking ? "直连" : "非直连",
                  type: "bar",
                  stack: "医院类型",
                  label: {
                    show: true,
                  },
                  emphasis: {
                    focus: "series",
                  },
                  data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                };
                item.data[r.month - 1] = r.successCount + r.failCount;
                series.push(item);
              } else {
                xxx[0].data[r.month - 1] = r.successCount + r.failCount;
              }
            });
            data.data = series;
            initPieEcharts();
            console.log(series);
          } else {
            ElMessage.warning({
              showClose: true,
              duration: 10000,
              message: res.ResultMsg,
            });
          }
        }
      );
    };

    const initBarEcharts = () => {
      type EChartsOption = echarts.EChartsOption;

      var chartDom = document.getElementById("barChart")!;
      var myChart = echarts.init(chartDom);
      var option: EChartsOption;

      option = {
        title: {
          text: data.formInline.year + "年挂号省份报表",
          padding: [
            5, // 上
            10, // 右
            5, // 下
            40, // 左
          ],
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {},
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: [
              "1月",
              "2月",
              "3月",
              "4月",
              "5月",
              "6月",
              "7月",
              "8月",
              "9月",
              "10月",
              "11月",
              "12月",
            ],
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: data.series,
      };

      option && myChart.setOption(option);
    };

    const initPieEcharts = () => {
      type EChartsOption = echarts.EChartsOption;

      var chartDom = document.getElementById("pieChart")!;
      var myChart = echarts.init(chartDom);
      var option: EChartsOption;

      option = {
        title: {
          text: data.formInline.year + "年挂号医院类型报表",
          padding: [
            5, // 上
            10, // 右
            5, // 下
            40, // 左
          ],
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {},
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: [
              "1月",
              "2月",
              "3月",
              "4月",
              "5月",
              "6月",
              "7月",
              "8月",
              "9月",
              "10月",
              "11月",
              "12月",
            ],
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: data.data,
      };

      option && myChart.setOption(option);
    };

    onMounted(() => {
      RegisterAnnualReportByProvince();
      RegisterAnnualReportByDocking();
    });

    return {
      ...toRefs(data),
      Reset,
    };
  },
});
